import { createApi } from '@reduxjs/toolkit/query/react'

import { selectStoreId } from '../features/app/selectors'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import {
	Exam,
	ExamApi,
	ExamBase,
	ExamQuery,
	StrippedExam,
	WorklistExamCodes,
} from '../model/exam'
import { useTeloSelector } from '../store'
import examsActions from '../features/exams/actions'

import { unversionedApiUrl } from './common'
import { serviceApi } from './serviceApi'
import {
	CheckInExamQueryArg,
	GetExamsQueryArg,
	ExamIdQueryArg,
	UseGetExamsQueryOptions,
} from './model'
import { ServicesReducerPath } from './constants'

export const examsApi = createApi({
	reducerPath: ServicesReducerPath.ExamsApi,
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	endpoints: builder => ({
		getExams: builder.query<Exam[], GetExamsQueryArg>({
			query: ({ query, storeId }) => ({
				url: serviceApi.getExams(query),
				headers: {
					'x-store': storeId,
				},
			}),
			// * clear cache when the last component that uses this query unmounts
			keepUnusedDataFor: 0,
		}),
		updateExams: builder.mutation<
			StrippedExam | ExamApi,
			{ data: Partial<ExamBase>[]; storeId?: string }
		>({
			query: ({ data, storeId }) => ({
				url: serviceApi.updateExams(),
				headers: {
					'x-store': storeId,
				},
				method: 'PUT',
				body: data,
			}),
		}),
		checkInExam: builder.mutation<StrippedExam | ExamApi, CheckInExamQueryArg>({
			query: ({ examId, ...payload }) => ({
				url: serviceApi.checkInExam(examId),
				method: 'PUT',
				body: JSON.stringify(payload),
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data: exam } = await queryFulfilled

					dispatch(examsActions._loadExam(exam))
				} catch {}
			},
		}),

		lockExam: builder.mutation<boolean, ExamIdQueryArg>({
			query: ({ examId }) => ({
				url: serviceApi.lockExam(examId),
				method: 'PATCH',
				body: JSON.stringify({}),
			}),
		}),
		getExamCodes: builder.query<
			{ _id: string; codes: WorklistExamCodes },
			ExamIdQueryArg
		>({
			query: ({ examId }) => ({
				url: serviceApi.getExamCodes(examId),
			}),
			keepUnusedDataFor: 0,
		}),
	}),
})

export const useGetExamsQuery = (
	query: ExamQuery,
	options?: UseGetExamsQueryOptions,
) => {
	const storeId = useTeloSelector(selectStoreId)
	return examsApi.useGetExamsQuery({ query, storeId }, options)
}

export const {
	useCheckInExamMutation,
	useLockExamMutation,
	useGetExamCodesQuery,
	useUpdateExamsMutation,
} = examsApi
