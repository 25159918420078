import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { convertDateForQueryString } from '../libs/time'
import {
	ProviderOption,
	CreateFhirAppointmentPayload,
} from '../model/appointment'
import { unversionedApiUrl } from '../services/common'

const AppointmentType_TAG = 'appointmentTypeApi'
const AppointmentProviders_TAG = 'appointmentProvidersApi'

export const appointmentTypeApi = createApi({
	reducerPath: 'appointmentTypeApi',
	tagTypes: [AppointmentType_TAG, AppointmentProviders_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getAppointmentList: builder.query<string[], string>({
			query: locationId => ({
				url: `/v1/practices/${locationId}/appointmenttypes`,
			}),
			providesTags: [AppointmentType_TAG],
			extraOptions: { maxRetries: 1 },
		}),
		getAppointmentProvidersList: builder.query<
			ProviderOption[],
			{ locationId: string }
		>({
			query: ({ locationId }) => ({
				url: `/v1/practices/${locationId}/providers`,
			}),
			providesTags: [AppointmentProviders_TAG],
			extraOptions: { maxRetries: 1 },
		}),
		createAppointment: builder.mutation<unknown, CreateFhirAppointmentPayload>({
			query: payload => ({
				url: '/v1/fhir-appointments',
				method: 'POST',
				body: JSON.stringify({
					...payload,
					date: convertDateForQueryString(new Date()),
				}),
			}),
		}),
	}),
})

export const {
	useGetAppointmentListQuery,
	useGetAppointmentProvidersListQuery,
	useCreateAppointmentMutation,
} = appointmentTypeApi
