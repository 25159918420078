import i18n from 'i18next'
import {
	createUserApi,
	createUserConditionApi,
	deleteSignatureApi,
	deleteUsersApi,
	getAllUsers,
	getPracticeUsersApi,
	getStaffUsers,
	getUserByUsernameAdminApi,
	getUserByUsernameApi,
	removeUserConditionApi,
	saveSignatureApi,
	setDefaultUserConditionApi,
	setDisabledLEConditionsApi,
	setUserAvailabilityApi,
	updatePathUserConditionApi,
	updateUserApi,
	updateUserIcdsApi,
	updateUserInStoreFlagApi,
} from '../../apiCalls'
import {
	checkStoreIdFormat,
	GetExternalIdentifierSource,
} from '../../libs/utils'
import { ExamCondition } from '../../model/examCondition'
import { Id } from '../../model/model'
import {
	ApiUser,
	CreateUserPayload,
	UpdateUserPayload,
	User,
	UserRole,
} from '../../model/users'
import { TeloNavigationFn } from '../../routing/teloRouter'
import {
	AppThunk,
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
} from '../../store'
import appActions from '../app/actions'
import { selectStoreId, selectStorePracticeExternalId } from '../app/selectors'
import notificationsActions from '../notifications/actions'
import panelsActions from '../panels/actions'
import practicesActions from '../practices/actions'
import storesActions from '../stores/actions'
import { selectIsInStoreUser, selectIsLocalTechnician } from './selectors'
import { slice } from './slice'
import timeoutsActions from '../timeouts/actions'
import authActions from '../auth/actions'

const fetchUser =
	(
		username: string,
		navigate: TeloNavigationFn,
	): AppThunkPromise<User | void> =>
	(dispatch: TeloDispatch, getState: TeloGetState) =>
		getUserByUsernameApi(username).then((user: ApiUser | void) => {
			if (!user) {
				return
			}
			dispatch(slice.actions._loadUser(user))
			if (
				user &&
				!user.loggedInAsInStore &&
				(user.role === UserRole.Doctor ||
					user.role === UserRole.Technician ||
					user.role === UserRole.Refractionist)
			) {
				dispatch(timeoutsActions.getTimeouts())
			}
			if (!user.stores || !user.stores.length) {
				return user
			}
			user.stores.forEach(s => checkStoreIdFormat(s.store._id, 'fetchUser'))
			dispatch(storesActions._setStores(user.stores.map(s => s.store)))

			const state = getState()
			const selectedStore = selectStoreId(state)
			const practiceId = selectStorePracticeExternalId(state)
			const isTechnician = selectIsLocalTechnician(state)
			const inStoreUser = selectIsInStoreUser(state)
			const isAdminPage = window.location.pathname.match('admin')
			const isStoreNotMandatory = !inStoreUser || isAdminPage
			const inStoreCount = user.stores.length

			if (isStoreNotMandatory) {
				return user
			}

			if (inStoreCount > 1 && !selectedStore) {
				navigate('/store-selection', { state: { fromLogin: true } })
				return user
			} else if (!selectedStore) {
				dispatch(appActions.setStoreId(user.stores[0].store._id))
				dispatch(practicesActions.getPracticeByStore(user.stores[0].store._id))
				dispatch(
					appActions.setPracticeExternalId(
						user.stores[0].store.externalIds.find(
							id => id.source === GetExternalIdentifierSource(),
						)?.code || '',
					),
				)
			} else if (selectedStore && !practiceId) {
				const storeCoop = user.stores.find(s => s.store._id === selectedStore)
				if (storeCoop) {
					dispatch(practicesActions.getPracticeByStore(storeCoop.store._id))
					const enableOtt = isTechnician
						? storeCoop.store.ottMode || false
						: false

					dispatch(appActions.setOttMode(enableOtt))
					dispatch(
						appActions.setPracticeExternalId(
							storeCoop.store.externalIds.find(
								id => id.source === GetExternalIdentifierSource(),
							)?.code || '',
						),
					)
				}
			}

			return user
		})

const fetchByUsername =
	(username: string) => async (dispatch: TeloDispatch) => {
		const user = await getUserByUsernameApi(username)
		if (!user) {
			return
		}
		dispatch(slice.actions._loadUser(user))
	}

const fetchByUsernameAdmin =
	(username: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		getUserByUsernameAdminApi(username).then((users: ApiUser[] | void) => {
			if (!users || users.length === 0 || !users[0]) {
				return
			}
			dispatch(slice.actions._loadUser(users[0]))
		})
	}

const fetchAllUsers = (): AppThunk => (dispatch: TeloDispatch) => {
	getAllUsers().then((users: ApiUser[] | void) => {
		if (!users || !users.length) {
			return
		}
		dispatch(slice.actions._loadUsers(users))
	})
}

const fetchStaffUsers =
	(storeId: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		getStaffUsers(storeId).then((users: ApiUser[] | void) => {
			if (!users || !users.length) {
				return
			}
			dispatch(slice.actions._loadUsers(users))
		})
	}

const fetchPracticeUsers =
	(practiceId: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		getPracticeUsersApi(practiceId).then((users: ApiUser[] | void) => {
			if (!users || !users.length) {
				return
			}
			dispatch(slice.actions._loadUsers(users))
		})
	}

const setUserAvailability =
	(userId: Id, available: boolean): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const currentSelectedStoreId = selectStoreId(state)
		const practiceId = selectStorePracticeExternalId(state)

		setUserAvailabilityApi(userId, available).then((user: ApiUser | void) => {
			if (!user) {
				return
			}

			dispatch(slice.actions._loadUser(user))

			if (!user.stores || !user.stores.length) {
				return
			}

			dispatch(storesActions._setStores(user.stores.map(s => s.store)))

			if (
				!user.stores.some(s => s.store._id === currentSelectedStoreId) &&
				state.app.inStore
			) {
				dispatch(appActions.setStoreId(user.stores[0].store._id))
				dispatch(practicesActions.getPracticeByStore(user.stores[0].store._id))
				dispatch(
					appActions.setPracticeExternalId(
						user.stores[0].store.externalIds.find(
							id => id.source === GetExternalIdentifierSource(),
						)?.code || '',
					),
				)
			} else if (
				currentSelectedStoreId &&
				currentSelectedStoreId !== '0' &&
				!practiceId
			) {
				const storeCooperation = user.stores.find(
					s => s.store._id === currentSelectedStoreId,
				)
				if (storeCooperation) {
					dispatch(
						practicesActions.getPracticeByStore(storeCooperation.store._id),
					)
					dispatch(
						appActions.setPracticeExternalId(
							storeCooperation.store.externalIds.find(
								id => id.source === GetExternalIdentifierSource(),
							)?.code || '',
						),
					)
				}
			}
		})
	}

const updateUser =
	(user: Partial<UpdateUserPayload>): AppThunk =>
	(dispatch: TeloDispatch) => {
		updateUserApi(user).then(
			(user: ApiUser | void) => user && dispatch(slice.actions._loadUser(user)),
		)
	}

const updateUserWithSignature =
	(_user: Partial<UpdateUserPayload>, signature?: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		updateUserApi(_user)
			.then((user: ApiUser | void) => {
				if (user && user.signature && !signature)
					return deleteSignatureApi(user.username)
				else if (user && signature)
					return saveSignatureApi(user.username, signature)
				else return user
			})
			.then(
				(user: ApiUser | void) =>
					user && dispatch(slice.actions._loadUser(user)),
			)
	}

const updateUserIcds =
	(user: Partial<UpdateUserPayload>): AppThunk =>
	(dispatch: TeloDispatch) => {
		updateUserIcdsApi(user).then(
			(user: ApiUser | void) => user && dispatch(slice.actions._loadUser(user)),
		)
	}

const updateUserInStoreFlag =
	(
		user: {
			_id: string
			loggedInAsInStore: boolean
		},
		updateToken?: boolean,
	): AppThunk =>
	(dispatch: TeloDispatch) => {
		updateUserInStoreFlagApi(user).then(savedUser => {
			dispatch(slice.actions._loadUser(savedUser))

			if (
				!savedUser.loggedInAsInStore &&
				(savedUser.role === UserRole.Doctor ||
					savedUser.role === UserRole.Technician ||
					savedUser.role === UserRole.Refractionist)
			) {
				dispatch(timeoutsActions.getTimeouts())
			}

			updateToken &&
				dispatch(authActions.setNewRefreshToken(savedUser?.username))

			dispatch(
				appActions.setInStore(
					savedUser.loggedInAsInStore ?? user.loggedInAsInStore,
				),
			)
		})
	}

const createUser =
	(
		_user: CreateUserPayload,
		navigate: TeloNavigationFn,
		signature?: string,
	): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		return createUserApi(_user)
			.then((user: ApiUser | void) =>
				user && signature ? saveSignatureApi(user.username, signature) : user,
			)
			.then(
				(user: ApiUser | void) =>
					user && dispatch(slice.actions._loadUser(user)),
			)
			.then(() => {
				const proms = (_user.panels || []).map(panel =>
					dispatch(panelsActions.addDoctorInPanel(panel, _user.username)),
				)
				return Promise.all(proms)
			})
			.then(() => navigate('/admin?selectedTab=users'))
			.then(() =>
				dispatch(
					notificationsActions.addNotification({
						message: i18n.t('users.creationSuccess'),
						autoClose: true,
						type: 'success',
						autoCloseDelay: 3000,
					}),
				),
			)
	}

const deleteUsers = (users: User[]) => (dispatch: TeloDispatch) => {
	const usersId = users.map(user => user._id)
	return deleteUsersApi(usersId).then(numberOfUsersDeleted => {
		numberOfUsersDeleted === users.length
			? users.map(user => dispatch(slice.actions._removeUser(user.username)))
			: dispatch(
					notificationsActions.addNotification({
						type: 'error',
						message: `Delete error: ${users.length} users had to be deleted, instead ${numberOfUsersDeleted} were deleted`,
						autoClose: false,
					}),
			  )
	})
}
const createUserCondition =
	(userId: string, condition: ExamCondition, id?: string) =>
	(dispatch: TeloDispatch) =>
		createUserConditionApi(userId, condition, id).then(
			(user: ApiUser | void) => user && dispatch(slice.actions._loadUser(user)),
		)

const removeUserCondition =
	(userId: string, conditions: ExamCondition[]) => (dispatch: TeloDispatch) =>
		removeUserConditionApi(userId, conditions).then(
			(user: ApiUser | void) => user && dispatch(slice.actions._loadUser(user)),
		)

const enableUserCondition =
	(userId: Id, id: string, value: boolean) => (dispatch: TeloDispatch) =>
		updatePathUserConditionApi(userId, id, 'enabled', value).then(
			(user: ApiUser | void) => user && dispatch(slice.actions._loadUser(user)),
		)

const defaultUserCondition =
	(userId: Id, id: string, value: boolean) => (dispatch: TeloDispatch) =>
		updatePathUserConditionApi(userId, id, 'default', value).then(
			(user: ApiUser | void) => user && dispatch(slice.actions._loadUser(user)),
		)

const setDefaultUserCondition =
	(userId: Id, id: string) => (dispatch: TeloDispatch) =>
		setDefaultUserConditionApi(userId, id).then(
			(user: ApiUser | void) => user && dispatch(slice.actions._loadUser(user)),
		)

const disabledLEConditions =
	(userId: Id, conditions: string[]) => (dispatch: TeloDispatch) =>
		setDisabledLEConditionsApi(userId, conditions).then(
			(user: ApiUser | void) => user && dispatch(slice.actions._loadUser(user)),
		)

const usersActions = {
	fetchUser,
	fetchByUsernameAdmin,
	fetchAllUsers,
	fetchStaffUsers,
	fetchPracticeUsers,
	setUserAvailability,
	updateUser,
	updateUserIcds,
	updateUserInStoreFlag,
	updateUserWithSignature,
	createUser,
	fetchByUsername,
	deleteUsers,
	createUserCondition,
	removeUserCondition,
	enableUserCondition,
	setDefaultUserCondition,
	defaultUserCondition,
	disabledLEConditions,
}

export default usersActions
