import React from 'react'

import AbortDialog from '../../components/AbortDialog'
import ChangeRoomDialog from '../../components/ChangeRoomDialog'
import ChangeStatusWarningDialog from '../../components/ChangeStatusWarningDialog'
import DeleteRoomDialog from '../../components/DeleteRoomDialog'
import DeleteStageDialog from '../../components/DeleteStageDialog'
import DeleteStoreDialog from '../../components/DeleteStoreDialog'
import DeleteUserDialog from '../../components/DeleteUserDialog'
import DeleteAppointmentDialog from '../../components/DeleteAppointmentDialog'
import LogoutDialog from '../../components/LogoutDialog'
import PrivacyPolicyDocumentDialog from '../../components/PrivacyPolicyDocumentDialog'
import QuitWithPrescribedDialog from '../../components/QuitWithPrescriptionDialog'
import QuitWithTrialDialog from '../../components/QuitWithTrialDialog'
import RemoveInstrumentDialog from '../../components/RemoveInstrumentDialog'
import TestExamDialog from '../../components/TestExamDialog'
import UncompletePrescriptionDialog from '../../components/UncompletePrescriptionDialog'
import UnlockDialog from '../../components/UnlockDialog'
import VisualSimulationShareDialog from '../../components/VisualSimulation/VisualSimulationShareDialog'
import VisualSimulationViewerDialog from '../../components/VisualSimulation/VisualSimulationViewerDialog'
import Dialog from '../../styleguide/dialog/Dialog'
import dialogActions from './actions'
import { selectDialog } from './selectors'
import { useTeloSelector, useTeloDispatch } from '../../store'
import LoadingDialog from '../../components/LoadingDialog'
import DoctorEndsWithoutRxModal from '../../components/DoctorEndsWithoutRxModal'
import DialogTitle from '../../styleguide/dialog/DialogTitle'
import SessionExpirationDialog from '../../components/SessionExpiration/SessionExpirationDialog'

const DialogBox = () => {
	const dialog = useTeloSelector(selectDialog)
	const dispatch = useTeloDispatch()
	const { type: dialogType, customTitle } = dialog

	return (
		<Dialog
			open={dialogType !== 'none'}
			onClose={() => dispatch(dialogActions.closeDialog())}
		>
			{!customTitle ? (
				<DialogTitle onClose={() => dispatch(dialogActions.closeDialog())} />
			) : null}
			{dialogType === 'abort' && <AbortDialog />}
			{dialogType === 'changeRoom' && <ChangeRoomDialog />}
			{dialogType === 'changeStatusWarningDialog' && (
				<ChangeStatusWarningDialog />
			)}
			{dialogType === 'deleteAppointment' && <DeleteAppointmentDialog />}
			{dialogType === 'deleteRoom' && <DeleteRoomDialog />}
			{dialogType === 'deleteStage' && <DeleteStageDialog />}
			{dialogType === 'deleteStore' && <DeleteStoreDialog />}
			{dialogType === 'deleteUser' && <DeleteUserDialog />}
			{dialogType === 'logout' && <LogoutDialog />}
			{dialogType === 'privacyPolicyDocument' && (
				<PrivacyPolicyDocumentDialog />
			)}
			{dialogType === 'quitWithPrescribed' && <QuitWithPrescribedDialog />}
			{dialogType === 'quitWithTrial' && <QuitWithTrialDialog />}
			{dialogType === 'doctorEndsWithoutRx' && <DoctorEndsWithoutRxModal />}
			{dialogType === 'removeInstrument' && <RemoveInstrumentDialog />}
			{dialogType === 'testExamDialog' && <TestExamDialog />}
			{dialogType === 'uncompletedPrescription' && (
				<UncompletePrescriptionDialog />
			)}
			{dialogType === 'unlockDialog' && <UnlockDialog />}
			{dialogType === 'visualSimulationShare' && (
				<VisualSimulationShareDialog />
			)}
			{dialogType === 'visualSimulationViewer' && (
				<VisualSimulationViewerDialog />
			)}
			{dialogType === 'loading' && <LoadingDialog message={dialog.message} />}
			{dialogType === 'sessionExpiration' && <SessionExpirationDialog />}
		</Dialog>
	)
}

export default DialogBox
