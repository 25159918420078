import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PatientSignatureSource } from '../../model/prescription'

import { prescriptionInitialState, prescriptionSliceName } from './constants'

export const prescriptionSlice = createSlice({
	name: prescriptionSliceName,
	initialState: prescriptionInitialState,
	reducers: {
		setPatientSignatureSource: (
			state,
			{ payload }: PayloadAction<PatientSignatureSource | undefined>,
		) => {
			state.patientSignatureSource = payload
		},
		setLoadingPrescription: (state, { payload }: PayloadAction<boolean>) => {
			state.loadingPrescription = payload
		},
		setContactLensesPresent: (
			state,
			{ payload }: PayloadAction<boolean | undefined>,
		) => {
			state.contactLensesPresent = payload
		},
	},
})

export default prescriptionSlice.reducer
