import { ExamHistory, ExamStatus } from './exam'
import { ExternalSource, FirstDataModel, Id } from './model'
import { TeloPatient } from './patient'
import { PrivacyPolicyDocumentDurationUnit } from './store'

export type AppointmentType =
	| 'COMP_VIS'
	| 'CONS/PRE'
	| 'DE'
	| 'DIAB_EX'
	| 'DMV_TEST'
	| 'DRI'
	| 'CL CK 14'
	| 'CL CK 6M'
	| 'CL CK 7'
	| 'EP CL NW'
	| 'EP CL'
	| 'EP E'
	| 'EP ONESIGHT'
	| 'EX CL RF'
	| 'F'
	| 'NP CL NW'
	| 'NP CL'
	| 'NP EEX'
	| 'NP ONESIGHT'
	| 'RX CK'
	| 'MED_EX'
	| 'CL FIT'
	| 'CL TRAIN'
	| 'CAT FU'
	| 'REF Sx FU'
	| 'SPEC_CL_FIT'
	| 'SPEC_CL_CK'
	| 'VT EX'
	| 'VT EVAL'
	| 'A10'
	| 'A20'
	| 'A30'
	| 'A40'
	| 'A50'
	| 'A60'
	| 'CAT FU'
	| 'CL CK 14'
	| 'CL CK 6M'
	| 'CL CK 7'
	| 'CL DISP'
	| 'CL FIT'
	| 'CL REFIT'
	| 'CL TRAIN'
	| 'CL20'
	| 'CL30'
	| 'CL40'
	| 'COMP_VIS'
	| 'CONS/PRE'
	| 'DE'
	| 'DEFERRED'
	| 'DFE'
	| 'DFE10'
	| 'DFE20'
	| 'DFE30'
	| 'DFE40'
	| 'DIAB_EX'
	| 'DMV_TEST'
	| 'DRI'
	| 'DRIVE'
	| 'DRY_CK'
	| 'DRY_TREAT'
	| 'EC'
	| 'EH CK'
	| 'EM10'
	| 'EM20'
	| 'EM30'
	| 'ENHAN CU'
	| 'ENHAN'
	| 'EP CL NW'
	| 'EP CL'
	| 'EP E'
	| 'EP EXTEN'
	| 'EP ONESIGHT'
	| 'EP_DIABET'
	| 'EP_DRY'
	| 'EP_MED_CK'
	| 'EP_MED'
	| 'EX CL F'
	| 'EX CL RF'
	| 'EXAM SR'
	| 'EYE OP'
	| 'F'
	| 'FS'
	| 'FU 3-6'
	| 'FU DAY'
	| 'FU MO'
	| 'FU WK'
	| 'FU YR'
	| 'GDX'
	| 'GLAUC_TEST'
	| 'HEALTH30'
	| 'HEALTH40'
	| 'HRT'
	| 'INFT SEE'
	| 'IOP'
	| 'LASIK CU'
	| 'LASIK'
	| 'LOW_VIS'
	| 'LSK CONS'
	| 'LSK FU'
	| 'MED_EX'
	| 'MONO CK'
	| 'MONO FIT'
	| 'NP CL NW'
	| 'NP CL'
	| 'NP EEX'
	| 'NP EX SR'
	| 'NP EXTEN'
	| 'NP ONESIGHT'
	| 'NP_DIABET'
	| 'NP_DRY'
	| 'NP_MED_CK'
	| 'NP_MED'
	| 'OCT'
	| 'OPTOMAP'
	| 'ORTHO CK'
	| 'ORTHO CS'
	| 'ORTHO E'
	| 'OV'
	| 'PENDING'
	| 'PRK CU'
	| 'PRK/AST'
	| 'PUNC PLG'
	| 'RECH'
	| 'RED EYE'
	| 'REF Sx FU'
	| 'RP'
	| 'RV10'
	| 'RV20'
	| 'RV30'
	| 'RX CK'
	| 'SAME_D'
	| 'SC'
	| 'SECOND20'
	| 'SECOND30'
	| 'SPEC_CL_CK'
	| 'SPEC_CL_FIT'
	| 'TELEOPTA'
	| 'TELEOPTV'
	| 'TGRAPH'
	| 'VF'
	| 'VT CK'
	| 'VT EVAL'
	| 'VT EX'
	| 'W-CL'
	| 'W-INT'
	| 'WALK_IN'
	| 'PR.NUCCI PAOLO AMB CONTROLLO PRIMARIO'
	| 'PR.NUCCI VALUTAZIONE ORTOTTICA'
	| 'PROF. NUCCI'
	| 'PROF. NUCCI AMB VAL.ORTOTTICHE'
	| 'EC'
	| 'EP_DIABET'
	| 'EP_DRY'
	| 'EP_MED_CK'
	| 'EX CL F'
	| 'INFT SEE'
	| 'LASIK'
	| 'LOW_VIS'
	| 'LSK CONS'
	| 'NP_DIABET'
	| 'NP_DRY'
	| 'NP_MED_CK'
	| 'OCT'
	| 'OV'
	| 'PRK/AST'
	| 'SAME_D'
	| 'VT CK'
	| 'WALK_IN'

export type GeminiAppointmentType =
	| 'Comprehensive New'
	| 'Comprehensive Est'
	| 'Comprehensive/CL New'
	| 'Comprehensive/CL Est'
	| 'Post-op'
	| 'Spectacle Re-check'
	| 'Special Testing'
	| 'Evaluation & Management'
	| 'Intermediate New'
	| 'Intermediate Est'

export interface ReasonWhy {
	appointmentType: AppointmentType | ''
	symptoms?: string
}

export type TabAppointmentData = {
	lockedId: string
	scheduleId: string
}

export interface AppointmentForm
	extends FirstDataModel,
		ReasonWhy,
		TabAppointmentData {
	appointmentDate: Date
	store: string
}

export interface Provider {
	firstName: string
	id: number
	lastName: string
	availableDOW: string
	scheduleOrder: number
	isTelemed?: boolean
}

export interface ProviderOption {
	id: string
	name: string
	surname: string
	isTelemed: boolean
}

export type TeloAppointment = {
	_id: Id
	appType: AppointmentType
	date: string
	externalIds: ExternalSource[]
	externalStatus: string
	isTelemed: boolean
	patient: TeloPatient
	provider: Provider
	source: string
	storeCode?: string
	timezone: string
}

export type TeloClinic = {
	address: string
	address2: string
	brand: string
	city: string
	country: string
	difEnabled: boolean
	email: string
	id: string
	name: string
	state: string
	storeNumber: string
	siteNumber: string
	storeType: string
	timeZone: string
	onConnect: boolean
	zipCode?: string
	externalIds: ExternalSource[]
}
export type AppointmentCompanion = TeloAppointment & {
	notes: string
}

export enum DOCUMENT_SIGNED_BY {
	PATIENT = 'PATIENT',
	PARENT = 'PARENT',
	GUARDIAN = 'GUARDIAN',
}

export type PrivacyPolicyDocumentGuarantor = {
	signedBy?: DOCUMENT_SIGNED_BY
	signerFullName?: string
	kinship?: string
}

export type AppointmentPrivacyPolicyDocumentSigned =
	PrivacyPolicyDocumentGuarantor & {
		storeId: Id
		documentId: Id
		patientId?: Id
		signatureImg: string
		date: string
		name: string
		mandatory: boolean
		expirationDate: string
		duration?: number
		durationUnit?: PrivacyPolicyDocumentDurationUnit
	}

export type PrivacyPolicyDocumentsSignedApi = {
	examId: Id
	privacyPolicyDocumentsSigned: AppointmentPrivacyPolicyDocumentSigned[]
}

export type CheckInData = TeloAppointment & {
	isTest?: boolean
}

export type AppointmentRange = {
	[key: string]: CheckInData
}

// Start TAB integration
export type LockId = string
export type ScheduleId = string

export type LockedSlot = {
	lockId: LockId
	scheduleId: ScheduleId
}

export type TimeSlot = {
	appointmentType: string
	appointmentDate: string
}

export type TimeSlotData = TimeSlot & {
	scheduleId: ScheduleId
	storeId: string
}

export type TimeSlotFhir = {
	h: number
	m: number
}

export type AppointmentWithStatus = CheckInData & {
	status: ExamStatus
	history: ExamHistory
	showOttCameraTooltip?: boolean
	showOttStatusTooltip?: boolean
}
export type AppointmentPayload = {
	user_id: string
	email: string
	role: string
}

export type CreateTabAppointmentPayload = {
	patientId: string
	lockId: string
	scheduleId: string
}

export type UpdateAppointmentPayload = {
	patientId: string
	lockId: string
	scheduleId: string
}

export type CreateFhirAppointmentPayload = {
	patientId: string
	appType: string
	storeId: string
	selectedSlot: TimeSlotFhir
	practitionerId: string
	remote: boolean
}
