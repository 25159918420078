import { ExamCondition } from './examCondition'
import { ExternalSource, Id } from './model'
import { Store } from './store'

export enum UserRole {
	TechnicalAdmin = 'TechnicalAdmin',
	GlobalAdmin = 'GlobalAdmin',
	Technician = 'Technician',
	Refractionist = 'Refractionist',
	Doctor = 'Doctor',
	Guest = 'Guest',
	FrontDesk = 'FrontDesk',
}

export type Role =
	| 'TechnicalAdmin'
	| 'GlobalAdmin'
	| 'Technician'
	| 'Refractionist'
	| 'Doctor'
	| 'Guest'
	| 'FrontDesk'

export type RoleForm = Role | ''

export interface License {
	country: string
	state: string
	number: string
	grants: {
		teleoptometry: boolean
		renewalPrescription: boolean
	}
}

export enum UserPrivilege {
	StoreManager = 'StoreManager',
	ReportManager = 'ReportManager',
	SetupManager = 'SetupManager',
	LegalEntityManager = 'LegalEntityManager',
	RightToBeForgotten = 'RightToBeForgotten',
}

export type Privilege =
	| 'StoreManager'
	| 'ReportManager'
	| 'SetupManager'
	| 'LegalEntityManager'
	| 'RightToBeForgotten'
	| ''

export type JobTitle = 'doctor' | 'optometrist' | 'ophthalmologist' | 'other'

export type PrivilegeFormRecord = Record<
	Id,
	{ StoreManager: boolean; ReportManager: boolean; SetupManager: boolean }
>

export interface StoreCooperation {
	store: Store
	privileges: Privilege[]
}

export interface PracticeDesignation {
	practice: Id
	privileges: Privilege[]
	name: string
	storeIds: string[]
}

export type Platform = 'teleoptometry' | 'cockpit'
export interface User {
	_id: Id
	active: boolean
	address?: string
	available: boolean
	birthDate?: Date
	city?: string
	country: string
	createdAt: Date
	doctorAdmin?: boolean
	email: string
	enableEPrescribing: boolean
	favoritesIcdCodes?: Id[]
	favoritesSnomedCTCodes?: string[]
	favoritesCptCodes?: string[]
	favoritesLoincCodes?: string[]
	languages: string[]
	licenses?: License[]
	loggedInAsInStore?: boolean
	name: string
	nationalProviderIdentifier?: string
	dea?: string
	pendingExam?: Id
	phone?: string
	profilePhoto?: string
	role: Role
	signature?: string
	state: string
	stores: StoreCooperation[]
	practices: PracticeDesignation[]
	surname: string
	updatedAt: Date
	username: string
	zip?: string
	jobTitle?: JobTitle
	jobTitleOther?: string
	timezone: string
	externalIds?: ExternalSource[]
	secureMessage?: boolean
	secureEmail?: string
	mipsAccess?: boolean
	conditions?: ExamCondition[]
	disabledLEConditions?: string[]
}

export interface UserAdmin extends User {
	profilePhoto?: string
	panels?: Id[]
}

export interface ApiUser extends Omit<User, 'role'> {
	role: Role
}

export interface UserStorePayload {
	store: Id
	privileges?: Privilege[]
}

export interface UpdateUserPayload extends Omit<ApiUser, 'stores'> {
	stores: UserStorePayload[]
}

export interface UpdateUserIcdsPayload {
	favoritesIcdCodes?: Id[]
}

// TODO: substitue this any with the proper type
export interface CreateUserPayload
	extends Omit<
		UserAdmin,
		'_id' | 'createdAt' | 'updatedAt' | 'stores' | 'pendingExam'
	> {
	stores?: UserStorePayload[]
}

export interface SignatureApi {
	photo: string
}

export interface ProfilePhotoApi {
	photo: string
	username: string
}

export interface UserForm extends Omit<User, 'stores' | 'role'> {
	profilePhoto?: string
	role: RoleForm
}

export interface AdditionalInfo {
	licenses?: License[]
	signatureBase64?: string
	nationalProviderIdentifier?: string
	enableEPrescribing: boolean
	jobTitle?: JobTitle
	jobTitleOther?: string
	dea?: string
	secureMessage?: boolean
	secureEmail?: string
	mipsAccess?: boolean
}

export interface Designation {
	inStoreStores: Id[]
	panels: Id[]
	practices?: PracticeDesignation[]
	privileges: PrivilegeFormRecord
}

export interface GetUsersQuery {
	roles?: string
	username?: string
	usernames?: string
	languages?: string[]
	locked?: 'true' | 'false'
	reportMode?: 'true' | 'false'
	storeId?: string
	practicesIds?: string
}
