import { AuxillaryTestingType } from './auxillaryTesting'
import { DiagnosticPharmaceuticalType } from './diagnosticPharmaceutical'
import {
	CLEvaluation,
	CLOverRefraction,
	PursuitSaccadesEyeData,
	VergeancesData,
	VergeancesVerticalData,
} from './exam'
import { FundusExamMethodsType } from './fundusExamMethods'
import { GonioscopyType } from './gonioscopy'
import {
	BloodPressureData,
	ColorTestData,
	ExtraocularAssessmentData,
	GoldmannTonometry,
	PachymetryManualData,
	PhoriaHorizontalAssessmentData,
	PhoriaVerticalAssessmentData,
	PupilTestingData,
	StereoTestData,
	VitalsData,
} from './manual'
import { Creator } from './model'
import { OctType } from './oct'
import {
	OcularHealthAnteriorSegment,
	OcularHealthFundus,
	OcularHealthOpticNerve,
} from './ocularHealthCondition'
import { PediatricsVitalsType } from './pediatricsVitals'
import { TactileTonometryType } from './tactileTonometry'
import { TypeCommons } from './types'

export type ExamTestsType =
	| 'acaRatio'
	| 'npa'
	| 'aniseikonia'
	| 'videoSliltLamp'
	| 'auxillaryTesting'
	| 'bloodPressure'
	| 'colorTest'
	| 'coverTestDistanceAlternating'
	| 'coverTestDistanceUnilateral'
	| 'coverTestNearAlternating'
	| 'coverTestNearUnilateral'
	| 'cycloplegicRefraction'
	| 'overRefraction'
	| 'evaluation'
	| 'diagnosticPharmaceutical'
	| 'retinoscopyDry'
	| 'extraocular'
	| 'fundus'
	| 'fundusExamMethods'
	| 'fusion'
	| 'goldmanTonometry'
	| 'gonioscopy'
	| 'npc'
	| 'nraPra'
	| 'oct'
	| 'opticalNerv'
	| 'phoria'
	| 'pupil'
	| 'pediatricsVitals'
	| 'pursuitsAndSaccades'
	| 'stereoTest'
	| 'stereopsys'
	| 'tactileTonometry'
	| 'vergeancesHorizontal'
	| 'vergeancesVertical'
	| 'vitalsData'
	| 'retinoscopyWet'
	| 'pachymetry'

type RetinoscopyEye = {
	sphere?: string
	cylinder?: string
	axis?: string
	va: string
	vaAdditional?: number
	reflexQuality?: string
}

type RetinoscopyFull = {
	OD?: RetinoscopyEye
	OS?: RetinoscopyEye
}

export type CycloplegicEye = {
	sphere?: string
	cyl?: string
	axis?: string
	add?: string
	hPrism?: string
	vPrism?: string
	hOrient?: string
	vOrient?: string
}

export type CycloplegicFull = {
	OD: CycloplegicEye
	OS: CycloplegicEye
}

export type coverTestUnilateral = {
	horizontalOD: string
	horizontalOS: string
	horizontalODBase: string
	horizontalOSBase: string
}

export type coverTestAlternating = {
	horizontal: string
	verticalOD: string
	verticalOS: string
	horizontalBase: string
	verticalODBase: string
	verticalOSBase: string
}

type PursuitSaccadesData = {
	OD: PursuitSaccadesEyeData
	OS: PursuitSaccadesEyeData
}

export type PanelProps = {
	readonly?: boolean
}

export type CreatedBy = Creator & { username: string | undefined }

export type ExamTests = {
	[key in ExamTestsType]?: boolean
}

export type ExamConditionSectionAcaRatio = {
	ratio: string
}

export type ExamConditionSectionNPA = {
	OD: string
	OS: string
	BIN: string
}

export type ExamConditionSectionAniseikonia = {
	distanceHorizontal: string
	nearHorizontal: string
}

export type ExamConditionSectionExtraOcular = {
	OD: ExtraocularAssessmentData
	OS: ExtraocularAssessmentData
}

export type ExamConditionSectionFusion = {
	fusion: string
	distance: string
	distanceImpression: string
	nearImpression: string
	near: string
}

export type ExamConditionSectionNPC = {
	blur: string
	break: string
	recovery: string
}

export type ExamConditionSectionNraPra = {
	nraBlur: string
	nraRecovery: string
	praBlur: string
	praRecovery: string
}

export type ExamConditionSectionPhoria = {
	horizontalDistance: PhoriaHorizontalAssessmentData
	verticalDistance: PhoriaVerticalAssessmentData
	verticalNear: PhoriaVerticalAssessmentData
	horizontalNear: PhoriaHorizontalAssessmentData
}

export type ExamConditionSectionStereopsys = {
	stereopsys: string
	distance: string
	near: string
	suppressionOD?: boolean
	suppressionOS?: boolean
}

export type ExamConditionSections = {
	acaRatio?: ExamConditionSectionAcaRatio
	npa?: ExamConditionSectionNPA
	aniseikonia?: ExamConditionSectionAniseikonia
	videoSliltLamp?: OcularHealthAnteriorSegment
	auxillaryTesting?: AuxillaryTestingType
	bloodPressure?: Omit<BloodPressureData, 'note'>
	colorTest?: ColorTestData
	coverTestNearAlternating?: coverTestAlternating
	coverTestNearUnilateral?: coverTestUnilateral
	coverTestDistanceAlternating?: coverTestAlternating
	coverTestDistanceUnilateral?: coverTestUnilateral
	cycloplegicRefraction?: CycloplegicFull
	overRefraction?: CLOverRefraction
	evaluation?: CLEvaluation
	diagnosticPharmaceutical?: DiagnosticPharmaceuticalType
	retinoscopyDry?: RetinoscopyFull
	extraocular?: ExamConditionSectionExtraOcular
	fundus?: OcularHealthFundus
	fundusExamMethods?: FundusExamMethodsType
	fusion?: ExamConditionSectionFusion
	goldmanTonometry?: GoldmannTonometry
	gonioscopy?: GonioscopyType
	npc?: ExamConditionSectionNPC
	nraPra?: ExamConditionSectionNraPra
	oct?: OctType
	opticalNerv?: OcularHealthOpticNerve
	pachymetry?: PachymetryManualData
	phoria?: ExamConditionSectionPhoria
	pupil?: PupilTestingData
	pediatricsVitals?: PediatricsVitalsType
	pursuitsAndSaccades?: PursuitSaccadesData
	stereoTest?: StereoTestData
	stereopsys?: ExamConditionSectionStereopsys
	tactileTonometry?: TactileTonometryType
	vergeancesHorizontal?: VergeancesData
	vergeancesVertical?: VergeancesVerticalData
	vitalsData?: Omit<VitalsData, 'notes'>
	retinoscopyWet?: RetinoscopyFull
}

export type ExamConditionPages =
	| 'pretest'
	| 'refraction'
	| 'ocularHealth'
	| 'auxiliary'
	| 'contactLenses'
type ExamConditionPagesMapType = Record<ExamConditionPages, ExamTestsType[]>

export const ExamConditionPagesMap: ExamConditionPagesMapType = {
	pretest: [
		'acaRatio',
		'npa',
		'npc',
		'aniseikonia',
		'videoSliltLamp',
		'bloodPressure',
		'colorTest',
		'coverTestNearAlternating',
		'coverTestNearUnilateral',
		'coverTestDistanceAlternating',
		'coverTestDistanceUnilateral',
		'nraPra',
		'phoria',
		'stereopsys',
		'fusion',
		'extraocular',
		'pupil',
		'pediatricsVitals',
		'vitalsData',
		'stereoTest',
		'pachymetry',
	],
	refraction: ['cycloplegicRefraction', 'retinoscopyDry', 'retinoscopyWet'],
	ocularHealth: [
		'fundus',
		'fundusExamMethods',
		'diagnosticPharmaceutical',
		'auxillaryTesting',
		'goldmanTonometry',
		'gonioscopy',
		'oct',
		'opticalNerv',
		'tactileTonometry',
	],
	auxiliary: [
		'vergeancesHorizontal',
		'vergeancesVertical',
		'pursuitsAndSaccades',
	],
	contactLenses: ['evaluation', 'overRefraction'],
}

export type ExamCondition = TypeCommons & {
	tests: ExamTests
	name: string
	enabled: boolean
	createdBy: CreatedBy
	default: boolean
	sections: ExamConditionSections
}
